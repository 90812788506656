import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getCurrentUser } from "./authAPI";
import { logOutUser, setUser } from "./authSlice";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../helpers/cookieManager";


export interface RequireLoginProps {
    children: JSX.Element
}
export default function RequireLogin({ children }: RequireLoginProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(state => state.auth.user);

    useEffect(() => {
        const logOut = () => {
            dispatch(logOutUser());
            navigate(routes.login.path);
        }

        if (getCookie("jplawt")) {
            if (!user) {
                (async () => {
                    try {
                        const res = await getCurrentUser();
                        dispatch(setUser(res.data));
                    } catch (e) {
                        logOut();
                    }
                })();
            }
            else if (user.passwordExpired) navigate(routes.changePassword.path);
        }
        else logOut();
    }, [dispatch, navigate, user]);

    return user ? children : null;
}