import { createTheme, PaletteMode, responsiveFontSizes } from "@mui/material";

export const getCustomTheme = (mode: PaletteMode) => responsiveFontSizes(createTheme({
    palette: { mode },
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& :-webkit-autofill': {
                        transitionDelay: "9999s"
                    },
                }
            }
        }
    }
}));