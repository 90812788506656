import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/es'; // import locale

export const loadDayjsConfig = () => {
    
    //Convert timezone
    dayjs.extend(utc);
    dayjs.extend(timezone);
    
    //Dayjs config
    dayjs.locale("es"); //dayjs in spanish
}