import axios from "axios"
import { LoginResponse } from "./interfaces/api/LoginResponse";
import { User } from "./interfaces/User";


export interface LoginRequest {
    email: string,
    password: string,
    code?: string
}
export const login = (data: LoginRequest) => {
    return axios.post<LoginResponse>("/introduce", {
        param0: data.password, //Password
        param1: data.email, //email
        param2: data.code, //2FA
    });
}

export const send2FAToLogin = (data: Omit<LoginRequest, "code">) => {
    return axios.post<{ recipient: string, info: string, result: string }>("/generate", {
        param0: data.password, //Password
        param1: data.email, //email
    });
}

export const sendPrivacyPolicyCode = (jplawt: string) => {
    return axios.post<{ recipient: string, result: string }>("/generate-legal-terms", { jplawt });
}

export interface AcceptPrivacyPolicyRequest { code: string, jplawt: string }
export const acceptPrivacyPolicy = ({ code, jplawt }: AcceptPrivacyPolicyRequest) => {
    return axios.post<{ result: string }>("/accept-legal-terms", { jplawt, param3: code });
}

export const getCurrentUser = async () => {
    const res = await axios.get<User>('/ajax', {
        params: { datasource: 'systemGetUserData' },
    });
    if (!res.data.dni) throw new Error("Unexpected error");
    return res;
}

export interface ChangePasswordRequest { password: string, newPassword: string, repeatNewPassword: string }
export const changePassword = (params: ChangePasswordRequest) => {
    return axios.post("/maindash/change-password", {
        oldpass: params.password,
        newpass: params.newPassword,
        newpass2: params.repeatNewPassword,
    })
}

export interface ResetPasswordRequest { email: string, phone: string }
export const resetPassword = ({ email, phone }: ResetPasswordRequest) => {
    return axios.post("/reset-password", {
        param0: email,
        param1: phone,
    });
}