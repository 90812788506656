import React, { useEffect, useState } from 'react';
import { Button, Container, CssBaseline, Drawer, Grid, Link, ThemeProvider, Typography } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { useAppSelector } from './app/hooks';
import { getCustomTheme } from './features/theme/customMuiTheme';
import { routes } from './routes';
import { loadDayjsConfig } from './config/dayjsConfig';
import './styles/fonts/Dubai-Font/dubai-font.css';
import './styles/fonts/Cabin-Font/cabin-font.css';
import { AxiosLoading } from '@jupol/react-axios-loading';
import { AxiosJplawtInterceptor } from '@jupol/react-axios-jplawt-interceptor';
import { loadBaseUrl, loadCredentials } from '@jupol/axios-config';
import { ChangePasswordProvider } from '@jupol/react-change-password-components';

loadDayjsConfig();
loadBaseUrl();
loadCredentials();

function App() {
  const mode = useAppSelector(state => state.theme.mode);
  const user = useAppSelector(state => state.auth.user);
  const theme = React.useMemo(() => getCustomTheme(mode), [mode]);
  checkUrl();

  return (
    <ThemeProvider theme={theme}>
      <ChangePasswordProvider>
        <CssBaseline />
        <Routes>
          {Object.values(routes).map(e => (
            <Route key={e.path} path={e.path} element={e.element} />
          ))}
          <Route path="*" element={<NoMatch />} />
        </Routes>
        <PrivacyPolicyDrawer />
        <AxiosLoading />
        {window.location.pathname !== "/" && <AxiosJplawtInterceptor defaultEmail={user?.email} />}
      </ChangePasswordProvider>
    </ThemeProvider>
  );
}

export default App;

function NoMatch() {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", flexDirection: "column" }}>
      <h2>No hay nada que ver aquí!</h2>
      <a href="/" style={{ color: "inherit" }}>Ir a la página de inicio</a>
    </div>
  );
}

export const checkUrl = () => {
  if (window.location.hostname !== "localhost" && (window.location.protocol === "http:" || window.location.host.startsWith("www."))) {
    window.location.href = window.location.href.replace("http://", "https://").replace("://www.", "://");
  }
}

function PrivacyPolicyDrawer() {
  const KEY = "privacyPolicyDisplayed";
  const [open, setOpen] = useState<boolean | undefined>();

  useEffect(() => setOpen(localStorage.getItem(KEY) === null), []);

  const handleClose = () => {
    localStorage.setItem(KEY, new Date().toISOString());
    setOpen(false);
  }

  if (typeof open !== "boolean") return null;
  return (
    <Drawer
      PaperProps={{ elevation: 1 }}
      anchor="bottom"
      open={typeof open === "boolean" && open}
      onClose={handleClose}
    >
      <Container>
        <Grid container spacing={2} py={2} display="flex" alignItems="center">
          <Grid item xs={12} sm={7} md={8} lg={9}>
            <Typography variant="body2">
              Esta página usa cookies para gestiones seguridad así como para obtener estadísticas anónimas de uso. El acceso a la misma implica su consentimiento expreso para su uso.
            </Typography>
          </Grid>
          <Grid item display="flex" justifyContent="end" xs={12} sm={5} md={4} lg={3}>
            <Button variant="contained" size="small" sx={{ width: { xs: "50%", sm: "auto" } }} onClick={handleClose}>Entendido</Button>
            <Button variant="outlined" color="inherit" size="small" sx={{ ml: 1, width: { xs: "50%", sm: "auto" } }} component={Link} href="https://jupol.es/politica-de-privacidad/" target="_blank">Más información</Button>
          </Grid>
        </Grid>
      </Container>
    </Drawer >
  )
}
