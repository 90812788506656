import React from "react";
import RequireLogin from "./features/auth/RequireLogin";
import { JupolLoading } from "@jupol/react-jupol-loading";


const LoginPage = React.lazy(() => import("./features/auth/LoginPage"));
const PrivacyPolicyPage = React.lazy(() => import("./features/auth/PrivacyPolicyPage"));
const ChangePasswordPage = React.lazy(() => import("./features/auth/ChangePasswordPage"));
const DashboardPage = React.lazy(() => import("./features/dashboard/DashboardPage"));

const addLoadingSuspense = (page: JSX.Element) => (<React.Suspense fallback={<JupolLoading open />}>{page}</React.Suspense>);

export const routes = {
    login: { path: "/", element: addLoadingSuspense(<LoginPage />) },
    privacyPolicy: { path: "/politica-de-privacidad", element: addLoadingSuspense(<PrivacyPolicyPage />) },
    changePassword: { path: "/cambio-de-contraseña", element: addLoadingSuspense(<ChangePasswordPage />) },
    dashboard: { path: "/mainDash", element: addLoadingSuspense(<RequireLogin><DashboardPage /></RequireLogin>) }
}