import axios from "axios";
import { setCookie } from "../../helpers/cookieManager";
import { User } from "./interfaces/User";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AuthState {
    user?: User,
}

const initialState: AuthState = {
    user: undefined,
};

export const themeSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        authenticateUser: (state, action: PayloadAction<{ user: User, jplawt: string }>) => {
            state.user = action.payload.user;
            setJplawt(action.payload.jplawt);
        },
        logOutUser: (state) => {
            state.user = undefined;
            setJplawt(undefined);
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
    },
});

export const { setUser, authenticateUser, logOutUser } = themeSlice.actions;

export default themeSlice.reducer;

export const setJplawt = (jplawt?: string) => {
    axios.defaults.headers.common["jplawt"] = jplawt;
    setCookie("jplawt", jplawt || "");
}